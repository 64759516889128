<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      :max-items="itemsPerPage"
      :data="user.role == 'admin' ? sales : mySales"
      pagination
      search
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ACTION - DROPDOWN -->
          <vs-dropdown
            vs-trigger-click
            class="dd-actions cursor-pointer mr-4 mb-4"
          >
            <div
              class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-full"
            >
              <span class="mr-2">Actions</span>
              <feather-icon icon="ChevronDownIcon" svg-classes="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="TrashIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span>Delete</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="ArchiveIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span>Archive</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="FileIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="SaveIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span>Another Action</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- ADD NEW -->
          <div
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewData"
          >
            <feather-icon icon="PlusIcon" svg-classes="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add New</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                sales.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : sales.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svg-classes="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="date">Date</vs-th>
        <vs-th sort-key="customer">Customer</vs-th>
        <vs-th sort-key="branch">Branch</vs-th>
        <vs-th sort-key="payment_method">Payment Method</vs-th>
        <vs-th sort-key="amount">Amount</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr v-for="(tr, indextr) in data" :data="tr" :key="indextr">
            <vs-td>
              <p class="sale-phone font-medium truncate">
                {{ parseDate(tr.date_created) }}
              </p>
            </vs-td>

            <vs-td>
              <p  class="sale-id font-medium truncate">
                {{ tr.customer ? tr.customer.name : "--"}}
              </p>
            </vs-td>

            <vs-td>
              <p class="sale-id font-medium truncate">
                {{ tr.branch.branch_name }}
              </p>
            </vs-td>

            <vs-td>
              <p
                class="sale-id font-medium truncate"
                :style="[
                  tr.void
                    ? { color: 'grey' }
                    : tr.credit
                      ? { color: 'red' }
                      : { color: 'green' },
                  { fontWeight: '800' },
                ]"
              >
                {{
                  tr.void
                    ? 'VOID'
                    : tr.credit
                      ? 'Credit - UNPAID'
                      : tr.payment ? tr.payment.name : "--"
                }}
              </p>
            </vs-td>

            <vs-td>
              <p class="sale-id font-medium truncate">
                {{
                  Number(tr.amount).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: $root.businessData.data.currency,
                  })
                }}
              </p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="FileTextIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="getReceipt(tr._id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import idb from '@/db/idb';

export default {
  components: {},
  data() {
    return {
      selected: [],
      itemsPerPage: 10,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    };
  },
  pouch: {
    saleData() {
      return {
        database: 'express',
        selector: { type: 'sale', void: false || undefined },
        sort: [
          {
            date_created: 'desc',
          },
        ],
      };
    },
    mySaleData() {
      return {
        database: 'express',
        selector: {
          type: 'sale',
          cashier: this.user._id,
          date_created: {
            $gte: new Date().setHours(0, 0, 0, 0),
          },
        },
        sort: [
          {
            date_created: 'desc',
          },
        ],
      };
    },
  },
  computed: {
    user() {
      return this.$session.get('user');
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    sales() {
      return this.saleData ? this.saleData : [];
    },
    mySales() {
      return this.mySaleData ? this.mySaleData : [];
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.sales.length;
    },
  },
  async created() {
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    getPaymentMethod(z) {
      var data = this.$store.state.finance.paymentMethods.find(
        (x) => x.id == z,
      );
      if (data) {
        return data['payment_method'];
      }
      return data;
    },
    parseDate(date) {
      return this.$moment(date).format('ddd DD MMM YYYY, h:mm:ss a');
    },
    addNewData() {
      this.$router.push('/store/sales/new').catch(() => {});
    },
    getReceipt(id) {
      this.$router.push(`/store/sales/${id}/receipt`).catch(() => {});
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .sale-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
